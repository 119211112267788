/**
 * Needs to be placed in the <head>, and before loading Alpine itself.
 *
 * Also, pull in AlpineJS 3.* at the end of the layout.
 */
document.addEventListener("alpine:init", () => {
  Alpine.data("reviewerBioModel", (reviewerId, bioScriptId) => {
    let bio = JSON.parse(document.getElementById(bioScriptId).innerText)

    return {
      reviewerId: reviewerId,
      content: bio.bio || "",
      saving: false,
      updated: false,
      show: false,

      save() {
        this.updated = false
        this.saving = true

        let data = { bio: this.content }
        fetch(`/profile/${this.reviewerId}/update`, {
          method: "POST",
          mode: "same-origin",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" }
        })
          .then((result) => {
            if (result.ok) {
              this.updated = true
              this.saving = false

              window.location.reload()
            } else {
              this.updated = false
              this.saving = false
            }
          })
          .catch((err) => {
            console.warn(err)
            this.updated = false
            this.saving = false
          })
      }
    }
  })

  Alpine.data("reportReviewModel", (reviewId) => {
    return {
      reviewId: reviewId,
      reported: false,
      report: function () {
        fetch(`/review/${this.reviewId}/report`, {
          method: "POST"
        })
          .then((resp) => resp.json())
          .then((_json) => {
            // json is a ReportCommentResponse
            // we could show how many have reported this comment here
            this.reported = true
          })
          .catch((err) => console.warn(err))
      }
    }
  })

  Alpine.data("localModel", (scriptId) =>
    JSON.parse(document.getElementById(scriptId).innerText)
  )
})
